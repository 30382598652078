import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import Layout from "../components/Layout/Layout"

const Our_team = (props) => {
    

    
    useEffect(() => {
        
    },[])
   
    return (
        <>
         <Layout>
        <section
            className='hero'
            
        >

                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <nav>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'>
                                            <Link to='/'>Home</Link>
                                        </li>
                                        <li className='breadcrumb-item active'>Our Team</li>
                                    </ol>
                                </nav>
                            </div>
                            
          <div className="col-md-8">
              {/* <p className="subheading text-uppercase text-white text-start">Comfort Zone</p> */}
                  <h2 className="fw-1 mt-3 text-white">We are the Professionals you need. We are a team of thinkers, makers and technology enthusiasts that pursue perfection.</h2>	
          </div>
      
                        </div>
                    </div>

        </section>
            <div>
        
            {/* <div className="hero">
    <div className="container">
      <div className="row">
          <div className="col-md-8">
              <p className="subheading text-uppercase text-white text-start">Comfort Zone</p>
                  <h2 className="fw-1 mt-3 text-white">We are the Professionals you need. We are a team of thinkers, makers and technology enthusiasts that pursue perfection.</h2>	
          </div>
      </div>
  </div>
</div> */}

<div className="heading-panel">
    <div className="container">
      <div className="row align-items-center">
          <div className="col-md-7">
                  <h2 className="fw-1 mt-3 text-white">Our Approach </h2>	
          </div>
          <div className="col-md-5">
              <p className="subheading text-white border-0 pb-0 mb-0">We deliver scalable business solutions by streamlining the right people, the right technology, and the right procedures. We also diligently always take care of data protection and security.</p>
          </div>
      </div>
  </div>
</div>
        {/* Comfort zone start here */}
        <div className="bg-comfort">
          <div className="container">
            <div className="py-5 row">
              <div className="col-md-12 col-lg-7">
                <p className="subheading text-uppercase">Comfort Zone</p>
                <h2 className="fw-1 mt-3 pb-3">Our Company Culture </h2>
                <div className="row mt-5 ">
                  <div className="col-md-6  mb-5 pb-3">
                    <div className="d-flex align-items-center gap-3">
                      <div><StaticImage src="../images/icon-1.png" alt=""/></div> 
                      <div className="fw-3 bl-heading">Strong & approachable leadership</div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5 pb-3">
                    <div className="d-flex align-items-center gap-3">
                      <div><StaticImage src="../images/icon-2.png" alt=""/></div> 
                      <div className="fw-3 bl-heading">Service Excellence Standards</div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5 pb-3">
                    <div className="d-flex align-items-center gap-3">
                      <div><StaticImage src="../images/icon-3.png" alt=""/></div> 
                      <div className="fw-3 bl-heading">Continuous Employee Developmente</div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5 pb-3">
                    <div className="d-flex align-items-center gap-3">
                      <div><StaticImage src="../images/icon-4.png" alt=""/></div> 
                      <div className="fw-3 bl-heading">Commitment to our Company Values</div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>    
          </div>
        </div>
        {/* Comfort zone end here */} 
        {/* Team start here */}
        <div className="bg-gray py-4">
          <div className="container">
            <div className="text-center">
          <p className="subheading text-center text-uppercase">Faces Behind Webuters</p>
            <h2 className="text-center mt-1 mb-5 pb-3 fw-1">Team Member's</h2>
            </div>
            <div className="row ">
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Techinical <br />Head </p>
                  <StaticImage src="../images/team-1.png" alt=""/>
                  <p className="tm-head">Karan <span>Singh</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Finance <br />Officer </p>
                  <StaticImage src="../images/team-2.png" alt=""/>
                  <p className="tm-head">Akash <span>Panchal</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Human <br />Resource </p>
                  <StaticImage src="../images/team-3.png" alt=""/>
                  <p className="tm-head">Kirti <span>Mehra</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Techinical <br />Lead </p>
                  <StaticImage src="../images/team-4.png" alt=""/>
                  <p className="tm-head">Saurabh <span>Dixit</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div> 
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Techinical <br />Head </p>
                  <StaticImage src="../images/team-1.png" alt=""/>
                  <p className="tm-head">Karan <span>Singh</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Finance <br />Officer </p>
                  <StaticImage src="../images/team-2.png" alt=""/>
                  <p className="tm-head">Akash <span>Panchal</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Human <br />Resource </p>
                  <StaticImage src="../images/team-3.png" alt=""/>
                  <p className="tm-head">Kirti <span>Mehra</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Techinical <br />Lead </p>
                  <StaticImage src="../images/team-4.png" alt=""/>
                  <p className="tm-head">Saurabh <span>Dixit</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div> 
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Techinical <br />Head </p>
                  <StaticImage src="../images/team-1.png" alt=""/>
                  <p className="tm-head">Karan <span>Singh</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Finance <br />Officer </p>
                  <StaticImage src="../images/team-2.png" alt="" />
                  <p className="tm-head">Akash <span>Panchal</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Human <br />Resource </p>
                  <StaticImage src="../images/team-3.png" alt="" />
                  <p className="tm-head">Kirti <span>Mehra</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div>
              {/* Team Name end here */}
              {/* Team Name start here */}
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="team">
                  <p className="desg">Techinical <br />Lead </p>
                  <StaticImage src="../images/team-4.png" alt=""/>
                  <p className="tm-head">Saurabh <span>Dixit</span></p>
                  <div className="overlay">
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Maecenas posuere quam in enim scelerisque sodales. Pellentesque accumsan pharetra tincidunt.</h2>
                    <p className="social"> SOCIAL
                      <span className="px-3">
                        <a href="#" className="px-2"><i className="fab fa-facebook-f" /></a> 
                        <a href="#" className="px-2"><i className="fab fa-linkedin-in" /></a>
                      </span>    
                    </p>
                  </div>    
                </div>
              </div> 
              {/* Team Name end here */}
            </div>
          </div>    
        </div>
        {/* Team end here */}
        {/* Gallery start here */}
        <div className="container">
          <div className="py-5 row">
            <div className="col-md-7">
              <p className="subheading text-uppercase">Gallery</p>
              {/* <h2 className="fw-1 mt-3 mb-5">Happy Team Member make <br /> Happy Clients</h2> */}
              <h2 className="fw-1 mt-0 mb-5">Together We Make <br/> The Magic Happen </h2>
            </div>
            <div className="col-md-12 mb-4 ">
              <div className="gallery-block">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <StaticImage src="../images/gallery-1.jpg" className="StaticImage-fluid" alt=""/>
                  </div>
                  <div className="col-md-8">
                    <div className="px-0 px-md-4 py-4 py-md-0">
                      <p className="subheading text-uppercase border-0">Our people</p>
                      <h3 className="fw-1 mb-4">We Are Defined By Our People</h3>
                      <p>We are proud that we have exceptional talent in our team that makes a considerable contribution to our success. We starve to hire more out of the box thinkers in our team so we could quickly deliver the desired result. <a href className="more">SEE MORE</a></p>						
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="gallery-block p-0">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="p-4">
                      <p className="subheading text-uppercase border-0">We are a team</p>
                      <h3 className="fw-1 mb-4">We Share A Common Goal</h3>
                      <p>We are proud that we have exceptional talent in our team that makes a considerable contribution to our success. We starve to hire more out of the box thinkers in our team. <a href className="more">SEE MORE</a></p>						
                    </div>
                  </div>
                  <div className="col-md-12">
                    <StaticImage src="../images/gallery-2.jpg" className="StaticImage-fluid" alt=""/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="gallery-block p-0">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="p-4">
                      <p className="subheading text-uppercase border-0">Transparency</p>
                      <h3 className="fw-1 mb-4">Our Professional Code Of Conduct</h3>
                      <p>To meet the highest professional and ethical standards we are committed to offering clear and transparent services to the customers. We understand that it's best to be precise. <a href className="more">SEE MORE</a></p>						
                    </div>
                  </div>
                  <div className="col-md-12">
                    <StaticImage src="../images/gallery-2.jpg" className="StaticImage-fluid" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </div>
        {/* Gallery end here */}
        {/* Goal start here */}
        <div className="bg-gray py-4">
          <div className="container">
            <div className="webuter-goal my-5 text-center">
              <div className="d-flex justify-content-center flex-column flex-md-row">
                <StaticImage src="../images/logo-webuters.png" alt=""/>
                <StaticImage src="../images/icon-plus.png" className="w-20 w-md-auto" alt=""/>
                <StaticImage src="../images/icon-team.png" alt=""/>
                <StaticImage src="../images/icon-plus.png" className="w-20 w-md-auto" alt=""/>
                <StaticImage src="../images/icon-client.png" alt=""bo/> 
              </div>
              <p className="text-center mt-5"><span>Together we make things Great!</span></p>
            </div>
            <div className="row my-3">
              <div className="col-md-5">
                <p className="subheading text-uppercase border-0">We think customer</p>
                <h3 className="fw-1 mb-4">We Value You And Your Time</h3>
              </div>
              <div className="col-md-7">
                <div className="px-0 px-md-5">
                  <p className="mb-5 pb-3">We are obsessed with the success of our customers. We set standards by offering cutting edge technology to our customers. The satisfaction and happiness of our customers is our ultimate achievement and we work to achieve it.</p>
                  <a href className="link-btn">Services</a> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; 
                  <a href className="link-btn">Let's Get Connect</a>
                </div>
              </div>
            </div>
          </div>	
        </div>	
        {/* Goal end here */}  
        {/* Career start here */}
        <div className="career  py-5">
          <div className="container py-2 py-md-5 my-md-4">
            <div className="row my-3 align-items-center">
              <div className="col-md-6">
                <p className="subheading text-uppercase">Career</p>
                <h3 className="fw-1 mb-5 pt-4">Be One of Us! </h3>
                <p>Grow professionally with a team of technology rockstars who love delivering the best-in-class results. Join our team and help us make things happen for a brighter future. </p>
              </div>
              <div className="col-md-6 text-start text-md-end text-right py-4 py-md-0">
                <a href className="link-btn">Let's join us</a>
              </div>
            </div>
          </div>	
        </div>	
        {/* Career end here */}
      </div>    
        </Layout>
        </>
    )
}


export default Our_team

